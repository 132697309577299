import { ContactComponent } from './pages/contact/contact.component';
import { SkillsComponent } from './pages/skills/skills.component';
import { ProjectComponent } from './pages/project/project.component';
import { ExperienceComponent } from './pages/experience/experience.component';
import { AboutComponent } from './pages/about/about.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EducationComponent } from './pages/education/education.component';
import { DonateComponent } from './pages/donate/donate.component';
import { Angular4PaystackModule } from 'angular4-paystack';

const routes: Routes = [
  {
    path: '',
    component: AboutComponent,
  },
  {
    path: 'about',
    redirectTo: '',
  },
  {
    path: 'experience',
    component: ExperienceComponent,
  },
  {
    path: 'projects',
    component: ProjectComponent,
  },
  {
    path: 'education',
    component: EducationComponent,
  },
  {
    path: 'skills',
    component: SkillsComponent,
  },
  {
    path: 'contact',
    component: ContactComponent,
  },
  {
    path: 'pay-me',
    component: DonateComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
