import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './component/layout/layout.component';
import { SidebarComponent } from './component/sidebar/sidebar.component';
import { AboutComponent } from './pages/about/about.component';
import { ExperienceComponent } from './pages/experience/experience.component';
import { ProjectComponent } from './pages/project/project.component';
import { ShortenPipe } from '../app/component/custom-pipe/shorten/shorten.pipe';
import { EducationComponent } from './pages/education/education.component';
import { SkillsComponent } from './pages/skills/skills.component';
import { ContactComponent } from './pages/contact/contact.component';
import { MobileHeaderComponent } from './component/mobile-header/mobile-header.component';
import { DonateComponent } from './pages/donate/donate.component';
import { Angular4PaystackModule } from 'angular4-paystack';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; // <-- #1 import module
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { AlertComponent } from './component/alert/alert.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CustomPaginationComponent } from './component/custom-pagination/custom-pagination.component';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';

const pk_test = 'pk_test_xxxxxxxxxxxxxxxxxxxxxxxx';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    SidebarComponent,
    AboutComponent,
    ExperienceComponent,
    ProjectComponent,
    ShortenPipe,
    EducationComponent,
    SkillsComponent,
    ContactComponent,
    MobileHeaderComponent,
    DonateComponent,
    AlertComponent,
    CustomPaginationComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    NgxPaginationModule,
    Angular4PaystackModule.forRoot(pk_test),
  ],
  providers: [{ provide: LocationStrategy, useClass: PathLocationStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
